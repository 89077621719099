import { Box, Button, Flex, Input, Select, Spacer, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useContext } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { AuthContextState } from '../../../../context/AuthContext'
import { createdToast, unauthenticateToast, updatedToast } from '../../../../lib/const/toast'
import { capFirstLetter } from '../../../../lib/helper/capitalizeFirstLetter'
import { getDateNow } from '../../../../lib/helper/getDateNow'
import { addVideo, getVideo, updateVideo } from '../../../../services/user.services'
import PageTitle from '../../_components/PageTitle'

function VideoAdd({ onEdit = false, customPage = false }) {
    const [, setTopRightButton] = useOutletContext()
    const [auth,] = useContext(AuthContextState)
    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState('')
    const [desc, setDesc] = useState('')
    const [descError, setDescError] = useState('')
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState('')
    const [date, setDate] = useState(getDateNow())
    const [visibility, setVisibility] = useState('public')
    const [loading, setLoading] = useState(true)
    const toast = useToast()
    const navigate = useNavigate()
    const param = useParams()
    const slugPage = customPage ? param.slugPage : ''
    const pageTitle = customPage ? capFirstLetter(slugPage.split('-').join(' ')) : 'Galeri Video'

    const onPublishHandler = () => {

        setTitleError('')
        setDescError('')
        setLinkError('')

        if (title.trim() === '') {
            setTitleError('Judul harus diisi')
            return
        }

        if (desc.trim() === '') {
            setDescError('Deskripsi harus diisi')
            return
        }

        if (link.trim() === '') {
            setLinkError('Link harus diisi')
            return
        }

        if (!/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/i.test(link)) {
            setLinkError('Link youtube tidak valid')
            return
        }


        setLoading(true)

        let payload = {
            title: title,
            description: desc,
            url: link,
            visibility: visibility,
            published_at: date
        }

        if (customPage) {
            payload = { ...payload, slugPage }
        }


        if (!onEdit) {

            addVideo(auth, payload)
                .then(data => {
                    toast(createdToast)
                    navigate(customPage ? `/halaman/${slugPage}/video` : '/beranda/video')
                    setLoading(false)
                })
                .catch(e => {
                    setLoading(false)
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                    console.log(e)
                })
        } else {
            updateVideo(auth, { id: param.id, ...payload })
                .then(data => {
                    console.log(data)
                    toast(updatedToast)
                    navigate(customPage ? `/halaman/${slugPage}/video` : '/beranda/video')
                    setLoading(false)
                })
                .catch(e => {
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                })
        }

    }


    useEffect(() => {

        if (!onEdit) {
            setLoading(false)
        } else {
            let payload = { id: param.id }

            if (customPage) {
                payload = { ...payload, slugPage }
            }
            getVideo(auth, payload)
                .then(data => {
                    const video = data.data.data
                    setTitle(video.title || '')
                    setLink(video.url || '')
                    setDesc(video.description || '')
                    setVisibility(video.visibility)
                    setDate(video.published_at ? video.published_at.slice(0, 10) : getDateNow())
                    setLoading(false)
                })
                .catch(e => {
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                })

        }
        setTopRightButton(null)
    }, [])
    return (
        <Fragment>
            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                <PageTitle title={pageTitle} showIcon={false} mt={0} mb={0} />
            </Box>
            <Box bg='gray.50' p={4} color='gray.700'>
                <Box>
                    <Text fontWeight={500}>Judul Video</Text>
                    <Input isInvalid={titleError} disabled={loading} maxLength={500} placeholder='Judul Video' bg='white' my={4} value={title} onChange={(e) => setTitle(e.target.value)} />
                    {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
                </Box>
                <Box>
                    <Text fontWeight={500}>Link Video</Text>
                    <Input isInvalid={linkError} disabled={loading} placeholder='Link Video' bg='white' my={4} value={link} onChange={(e) => setLink(e.target.value)} />
                    {linkError ? <Text fontSize={14} color='red.500'>{linkError}</Text> : ''}
                </Box>
                <Box mt={6}>
                    <Text fontWeight={500}>Deskripsi Video</Text>
                    <Textarea isInvalid={descError} disabled={loading} maxLength={3000} placeholder='Deskripsi Video' bg='white' my={4} value={desc} onChange={(e) => setDesc(e.target.value)} />
                    {descError ? <Text fontSize={14} color='red.500'>{descError}</Text> : ''}
                </Box>

                <Box mt={8} color='gray.700' bg='gray.50' rounded={4}>
                    <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                        <Text>Publish</Text>
                    </Box>
                    <Box p={4} w='50%'>
                        <Box pb={4}>
                            <Text>Visibility</Text>
                            <Select bg='white' disabled={loading} value={visibility} onChange={(e) => setVisibility(e.target.value)}>
                                <option value='private' >Private</option>
                                <option value='public' >Publik</option>
                            </Select>
                        </Box>
                        <Box pb={4}>
                            <Text>Publish</Text>
                            <Input disabled={loading} type='date' value={date} onChange={(e) => { setDate(e.target.value); console.log(e.target.value) }} bg='white' min={new Date().toISOString().split("T")[0]} placeholder='Tanggal Publish' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Flex my={4}>
                <Spacer />
                <Button onClick={onPublishHandler} disabled={loading} colorScheme='teal'>Simpan</Button>
            </Flex>
        </Fragment>
    )
}

export default VideoAdd