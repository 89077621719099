import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { Box, Button, Center, Flex, Img, Input, InputGroup, InputRightElement, Select, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { useRef } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { bannerColumns } from '../../../../lib/const/table-columns'
import CustomModal from '../../_components/CustomModal'
import TableData from '../../_components/TableData'
import FileUpload from '../../_components/FileUpload'
import { useState } from 'react'
import { Fragment } from 'react'
import { addBanner, deleteBanner, getBanner, updateBanner } from '../../../../services/user.services'
import { deletedToast, imageEmptyToast, unauthenticateToast } from '../../../../lib/const/toast'
import noImage from '../../../../assets/images/no-image.png'
import PageTitle from '../../_components/PageTitle'
import { AuthContextState } from '../../../../context/AuthContext'

function Banner() {
    const [auth] = useContext(AuthContextState)
    const toast = useToast()
    const [banners, setBanners] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [, setTopRightButton] = useOutletContext()
    const disclosureInit = useDisclosure()
    const addBannerRef = useRef(null)

    // Add Banner
    const [imageUpload, setImageUpload] = useState(null)
    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState('')
    const [id, setId] = useState('')
    const [imgPreview, setImgPreview] = useState('')

    const [onEdit, setOnEdit] = useState(false)
    const disclosure = {
        ...disclosureInit,
        onClose: () => {
            disclosureInit.onClose()
            setOnEdit(false)
        }
    }

    // Begin Table Handler Function ========================================

    const publishHandler = () => {
        setTitleError('')

        if (title === ' ' || title === '') {
            setTitleError('Title cannot be empty')
            return
        }

        if (imageUpload === null && !onEdit) {
            toast(imageEmptyToast)
            return
        }

        setLoading(true)
        if (!onEdit) {

            addBanner(auth, { image: imageUpload, title })
                .then(data => {
                    console.log(data)
                    disclosure.onClose()
                    setImageUpload(null)
                    setTitle('')
                    init()
                })
                .catch(e => {
                    console.log(e)
                    setLoading(false)
                })
        } else {
            console.log('UPDATE')
            updateBanner(auth, { id: id, image: imageUpload, title: title })
                .then(data => {
                    console.log(data.data.data)
                    disclosure.onClose()
                    setImageUpload(null)
                    setTitle('')
                    init()
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }

    const deleteHandler = (id) => {
        setLoading(true)
        deleteBanner(auth, { id })
            .then(data => {
                navigate('/beranda/banner')
                toast(deletedToast)
                console.log(data)
                init()
            })
            .catch(e => {
                setLoading(false)
                console.log(e)
            })
    }

    const responseHandler = (response) => {
        console.log(response.data.data)
        const banner = response.data.data.map(item => {
            item.action = {
                edit: () => {
                    disclosure.onOpen()
                    setImgPreview(item.url_thumbnail)
                    setLoading(true)
                    getBannerHandler(item.id)
                    setOnEdit(true)
                    setId(item.id)
                },
                delete: () => deleteHandler(item.id),
                isDisabled: item.type === 'post'
            }
            return item
        })

        setBanners(banner)

        setMetaTable({
            //     current_page: response.data.meta.current_page,
            per_page: banner.length,
            from: 1,
            to: banner.length,
            total: banner.length,
            //     next: response.data.links.next,
            //     prev: response.data.links.prev,
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)
        const { status } = e.response

        if (status === 401) {
            toast(unauthenticateToast)
            navigate('/auth/logout')
        }

        setLoading(false)
    }

    const getBannerHandler = (id) => {
        getBanner(auth, { id })
            .then(data => {
                const banner = data.data.data
                console.log(banner)
                setTitle(banner.title)
                setLoading(false)
            })
            .catch(e => {
                console.log(e)
            })
    }

    // const updateBannerHandler = () => {
    //     updateBanner(auth, { id, image: imageUpload, title })
    //         .then(data => {
    //             console.log(data.data.data)
    //         })
    //         .catch(e => {
    //             console.log(e)
    //         })
    // }

    const nextPageHandler = () => {
        setLoading(true)
        getBanner(auth, metaTable.current_page + 1)
            .then(responseHandler)
            .catch(errorHandler)
    }

    const prevPageHandler = () => {
        setLoading(true)
        getBanner(auth, metaTable.current_page - 1)
            .then(responseHandler)
            .catch(errorHandler)
    }

    const init = () => {
        getBanner(auth)
            .then(responseHandler)
            .catch(errorHandler)
    }

    const tableProps = {
        columns: bannerColumns,
        data: banners,
        isLoading,
        metaTable,
        nextPageHandler,
        prevPageHandler
    }
    // End Table Handler Function ========================================

    const customModalBodyProps = {
        title, setTitle, titleError, setTitleError, imageUpload, setImageUpload, isLoading, imgPreview
    }

    useEffect(() => {
        init()
        setTopRightButton({
            text: 'Tambah Banner',
            icon: <AddIcon />,
            onClick: disclosure.onOpen
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <CustomModal loading={isLoading} disclosure={disclosure} ref={addBannerRef} closeOnOverlayClick={false} title={onEdit ? 'Ubah Banner' : 'Tambah Banner'} body={AddBannerBody(customModalBodyProps)} footer={AddBannerFooter({ disclosure, publishHandler, loading: isLoading, onEdit, setOnEdit })} />
            <PageTitle mb={4} title='Banner' />
            <Flex>
                <Select placeholder='Tanggal' bg='white' width='20%' mr={2} />
                <Select placeholder='Kategori' bg='white' width='20%' />
                <Spacer />
                <InputGroup width='30%'>
                    <Input placeholder='Ketik untuk mencari' bg='white' />
                    <InputRightElement color='gray.700' >
                        <Search2Icon />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <TableData {...tableProps} />
        </Box>
    )
}

function AddBannerBody({ setTitleError, titleError, title, setTitle, isLoading, imageUpload, setImageUpload, imgPreview }) {

    return (
        <Fragment>

            <Box>
                <Text fontWeight={500}>Judul</Text>
                <Input isInvalid={titleError} disabled={isLoading} placeholder='Judul' bg='white' my={4} value={title} maxLength={255} onChange={(e) => setTitle(e.target.value)} />
                {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
            </Box>
            {
                imageUpload ?
                    ''
                    :
                    <Box >
                        <Center>
                            <Img src={imgPreview} maxH={200} onError={(e) => e.target.src = noImage} />
                        </Center>
                    </Box>
            }
            <FileUpload accept='image/jpeg,image/png' isLoading={isLoading} fileHandler={[imageUpload, setImageUpload]} width='100%' />
        </Fragment>
    )
}

function AddBannerFooter({ disclosure, publishHandler, loading, onEdit, setOnEdit }) {
    return (
        <Fragment>
            <Button disabled={loading} colorScheme='blue' mr='4' onClick={publishHandler} >{onEdit ? 'Update' : 'Upload'}</Button>
            <Button disabled={loading} onClick={disclosure.onClose}>
                Kembali
            </Button>
        </Fragment>
    )
}

export default Banner