import axios from "axios"
import { BASE_API_URL } from "./_base.api.url"

axios.interceptors.response.use(response => response, e => {
    if (401 === e.response.status) {
        window.location.href = "/auth/logout";
    } else {
        return Promise.reject(e)
    }
})

const HEADER = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
}

// CHECK LOGIN STATUS
export const getLoginStatus = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/custom-content?name!eq=home_t&except=id`,
        headers: HEADER
    })
}


// IRE UPDATE API
export const getAllIreUpdate = (user, { page = 1, slugPage = 'ire-update' }) => {

    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${slugPage}/posts?page=${page}&sort[]=-created_at&sort[]=-pinned_at`,
        headers: HEADER
    })
}

export const addIreUpdateTitle = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'ire-update'}/posts`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const getIreUpdate = (user, data) => {

    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'ire-update'}/posts/${data.slug}`,
        headers: HEADER
    })
}

export const addIreUpdateContent = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'ire-update'}/posts/${data.slug}`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const addIreUpdateThumbnail = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    const formData = new FormData()
    formData.append('image', data.image)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'ire-update'}/posts/${data.slug}/thumbnail`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: formData
    })
}

export const deleteIreUpdate = (user, { slug, slugPage = 'ire-update' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'DELETE',
        url: `${BASE_API_URL}/pages/${slugPage}/posts/${slug}`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
    })
}

export const setPinIreUpdate = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'ire-update'}/posts/${data.slug}/pin`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

// PUBLIKASI API

export const getAllPublikasi = (user, { page = 1, slugPage = 'publikasi' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${slugPage}/posts?page=${page}&sort[]=-created_at&sort[]=-pinned_at`,
        headers: HEADER
    })
}

export const getPublikasi = (user, { slug, slugPage = 'publikasi' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${slugPage}/posts/${slug}`,
        headers: HEADER,
    })

}

export const getKajian = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/keywords`,
        headers: HEADER,
    })

}

export const addPublikasiTitle = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'publikasi'}/posts`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const addPublikasiThumbnail = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    const formData = new FormData()
    formData.append('image', data.image)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'publikasi'}/posts/${data.slug}/thumbnail`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: formData
    })
}

export const addPublikasiContent = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'publikasi'}/posts/${data.slug}`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const addPublikasiDoc = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    const formData = new FormData()
    formData.append('attachment', data.file)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'publikasi'}/posts/${data.id}/publication`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: formData
    })
}

export const deletePublikasi = (user, { slug, slugPage = 'publikasi' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'DELETE',
        url: `${BASE_API_URL}/pages/${slugPage}/posts/${slug}`,
        headers: HEADER,
    })
}

export const setPinPublikasi = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'publikasi'}/posts/${data.slug}/pin`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

// PAGES API
export const getAllPages = (user, page = 1) => {

    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages`,
        headers: HEADER
    })
}

export const getPage = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${id}`,
        headers: HEADER
    })
}

export const getCategories = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/categories${id ? '/' + id : ''}`,
        headers: HEADER
    })
}

export const addPages = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const UpdatePages = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    // console.log(data)
    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/pages/${data.id}`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const deletePages = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'DELETE',
        url: `${BASE_API_URL}/pages/${id}`,
        headers: HEADER
    })

}

// GALLERY FOTO API
export const getAllPhoto = (user, { page = 1, slugPage = 'proyek' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${slugPage}/photos?page=${page}&sort[]=-created_at&sort[]=-pinned_at`,
        headers: HEADER,
    })

}

export const getPhoto = (user, data,defaultSlugPage = 'proyek') => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage }/photos/${data.id}`,
        headers: HEADER,
    })
}

export const addPhotoTemp = (user, data,defaultSlugPage = 'proyek') => {
    // HEADER['X-Api-Key'] = `Bearer ${user.token}`
    const formData = new FormData()
    formData.append('file', data.file)
    // formData.append('type', 'image')

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/photos/temp`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: formData
    })
}

export const updateFilePhoto = (user, data, defaultSlugPage = 'proyek') => {
    const formData = new FormData()
    formData.append('file', data.file)
    // formData.append('type', 'image')

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/photos/${data.id}/image`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: formData
    })
}

export const addPhoto = (user, data, defaultSlugPage = 'proyek') => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    // console.log(data)
    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/photos`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const updatePhoto = (user, data, defaultSlugPage = 'proyek') => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'application/json'

    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/photos/${data.id}`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const deletePhoto = (user, { id, slugPage = 'proyek' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'DELETE',
        url: `${BASE_API_URL}/pages/${slugPage}/photos/${id}`,
        headers: HEADER
    })
}

export const setPinPhoto = (user, data,defaultSlugPage = 'proyek') => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage }/photos/${data.id}/pin`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: data
    })
}


// GALLERY VIDEO API
export const getAllVideo = (user, { page = 1, slugPage = 'galeri-video' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${slugPage}/videos?page=${page}&sort[]=-created_at&sort[]=-pinned_at`,
        headers: HEADER,
    })

}

export const getVideo = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'application/json'

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'galeri-video'}/videos/${data.id}`,
        headers: HEADER,
    })
}

export const addVideo = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'galeri-video'}/videos`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const updateVideo = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'galeri-video'}/videos/${data.id}`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const deleteVideo = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    console.log(data)
    return axios({
        method: 'DELETE',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'galeri-video'}/videos/${data.slug}`,
        headers: HEADER,
    })
}

export const setPinVideo = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : 'galeri-video'}/videos/${data.id}/pin`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

// COMMENTS API

export const getAllComments = (user, page = 1) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/comments?page=${page}&sort=-created_at`,
        headers: HEADER,
    })

}

export const setCommentApproval = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/comments/${data.id}/approved`,
        headers: HEADER,
        data: JSON.stringify({
            approved: data.value
        })
    })
}

//* BANNER API
export const getAllHomeBanner = (user, page = 1) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/home-banners?page=${page}&sort[]=-created_at`,
        headers: HEADER,
    })
}

/**
 * Get all banner and post
 *
 * @param {string} user User token
 * @param {number} page Pagination
 * @param {*} q Search query
 * @returns
 */
export const getAllBannerAndPost = (user, page = 1, q = null) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    let url = `${BASE_API_URL}/banners/posts?page=${page}`;
    if (q) url += `&q=${q}`;
    return axios({
        method: 'GET',
        url: url,
        headers: HEADER,
    })
}


export const getBanner = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    let url = `${BASE_API_URL}/banners`;
    if (data && data.id) {
        url += `/${data.id}`;
    }

    return axios({
        method: 'GET',
        url: url,
        headers: HEADER,
    })
}

export const addBanner = (user, data) => {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('image', data.image)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/banners`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: formData
    })
}

export const updateBanner = (user, data) => {
    const formData = new FormData()
    formData.append('title', data.title)
    if (data.image) {
        formData.append('image', data.image)
    }

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/banners/${data.id}/update`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'multipart/form-data',
        },
        data: formData
    })
}

export const deleteBanner = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    let url = `${BASE_API_URL}/banners/${data.id}`;

    return axios({
        method: 'DELETE',
        url: url,
        headers: HEADER,
    })
}

// ORGANIZATION API

export const getTeams = (user, page = 1) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/teams?page=${page}&sort=order`,
        headers: HEADER,

    })
}

export const getTeam = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/teams/${id}`,
        headers: HEADER,
    })
}

export const addTeam = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    //Multi part form data
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('position', data.position)
    formData.append('image', data.image)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/teams`,
        headers: HEADER,
        data: formData
    })
}

export const updateTeam = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    //Multi part form data
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('position', data.position)
    if (data.image)
        formData.append('image', data.image)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/teams/${data.id}?_method=PUT`,
        headers: HEADER,
        data: formData
    })
}

export const deleteTeam = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'DELETE',
        url: `${BASE_API_URL}/teams/${id}`,
        headers: HEADER,
    })
}

export const pinTeam = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/teams/${id}/visibility?_method=PUT`,
        headers: HEADER,
        data: JSON.stringify({
            visibility: 'public'
        })
    })
}

export const unpinTeam = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/teams/${id}/visibility?_method=PUT`,
        headers: HEADER,
        data: JSON.stringify({
            visibility: 'private'
        })
    })
}

export const changeTeamOrder = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    console.log('send', data)
    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/teams/order`,
        headers: HEADER,
        data: JSON.stringify({
            orders: data
        })
    })
}

// ABOUT API

export const getAbout = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/tentang-kami`,
        headers: HEADER,
    })
}

export const updateAbout = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    let formData = new FormData()
    formData.append('description_post', data.description_post)
    formData.append('description', data.description)
    if (data.image)
        formData.append('image', data.image)
    if (data.banner)
        formData.append('banner', data.banner)
    if (data.thumbnail)
        formData.append('thumbnail', data.thumbnail)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/tentang-kami`,
        headers: HEADER,
        data: formData
    })
}

// IRE NEXT GENERATION API

export const getIreNextGeneration = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/ire-next-gen`,
        headers: HEADER,
    })
}

export const updateIreNextGeneration = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    let formData = new FormData()
    formData.append('description', data.description)
    formData.append('description_post', data.description_post)
    if (data.image) {
        formData.append('image', data.image)
    }
    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/ire-next-gen`,
        headers: HEADER,
        data: formData
    })
}
// PERPUSTAKAAN API

export const getPerpustakaan = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/library`,
        headers: HEADER,
    })
}

export const updatePerpustakaan = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    let formData = new FormData()
    formData.append('description', data.description)
    // formData.append('description_post', data.description_post)
    if (data.image) {
        formData.append('image', data.image)
    }
    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/library`,
        headers: HEADER,
        data: formData
    })
}

// COUNT API

export const getCount = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/count`,
        headers: HEADER,
        data: JSON.stringify({
            model: 'all',
        })
    });
}

// DOWNLOAD API

export const getFormDownloads = (user, page = 1) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/form-download?page=${page}&sort=-updated_at`,
        headers: HEADER,

    })
}

export const getFormDownload = (user, id) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/form-download/${id}`,
        headers: HEADER,
    })
}

export const getExportedData = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    // HEADER['Content-Type'] = 'blob'

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/form-download/export`,
        headers: HEADER,
        data: JSON.stringify({
            start_from: data.startDate,
            end_at: data.endDate,
        }),
        responseType: 'arraybuffer'
    })
}

export const getStatsChart = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/count/form-download`,
        headers: HEADER,
    })
}

// OPENING API

export const getOpening = (user) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/opening`,
        headers: HEADER,
    })
}

export const updateOpening = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    let formData = new FormData()
    formData.append('title', data.title)
    formData.append('description', data.description)
    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/opening`,
        headers: HEADER,
        data: formData
    })
}

// KEUNGGULAN API

export const getKeunggulan = (user, id = '') => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    let url = `${BASE_API_URL}/keunggulan`
    if (id) {
        url += `/${id}`
    }
    return axios({
        method: 'GET',
        url: url,
        headers: HEADER,
    })
}

export const updateKeunggulan = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    let formData = new FormData()
    formData.append('title', data.title)
    formData.append('description', data.description)
    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/keunggulan/${data.id}`,
        headers: HEADER,
        data: formData
    })
}

// POST API

export const getAllPost = (user, defaultSlugPage, { page = 1, slugPage = '' }) => {

    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    let url = `${BASE_API_URL}/pages`;
    url += `/${slugPage ? slugPage : defaultSlugPage}`


    url += `/posts?page=${page}&sort[]=-created_at&sort[]=-pinned_at`

    return axios({
        method: 'GET',
        url: url,//`${BASE_API_URL}/pages/${slugPage}/posts?page=${page}&sort=-created_at&sort=-pinned_at`,
        headers: HEADER
    })
}

export const addPostTitle = (user, defaultSlugPage, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    let url = `${BASE_API_URL}/pages`;
    url += `/${data.slugPage ? data.slugPage : defaultSlugPage}`
    url += `/posts`

    return axios({
        method: 'POST',
        url: url,//`${BASE_API_URL}/pages/${slugPage ? slugPage : 'ire-update'}/posts`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const getPost = (user, defaultSlugPage, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    return axios({
        method: 'GET',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/posts/${data.slug}`,
        headers: HEADER
    })
}

export const addPostContent = (user, defaultSlugPage, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'PUT',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/posts/${data.slug}`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const addPostThumbnail = (user, defaultSlugPage, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    const formData = new FormData()
    formData.append('image', data.image)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/posts/${data.slug}/thumbnail`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
        data: formData
    })
}

export const deletePost = (user, defaultSlugPage, { slug, slugPage = '' }) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'DELETE',
        url: `${BASE_API_URL}/pages/${slugPage ? slugPage : defaultSlugPage}/posts/${slug}`,
        headers: {
            'X-Api-Key': `Bearer ${user.token}`,
        },
    })
}

export const setPinPost = (user, defaultSlugPage, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/pages/${data.slugPage ? data.slugPage : defaultSlugPage}/posts/${data.slug}/pin`,
        headers: HEADER,
        data: JSON.stringify(data)
    })
}

export const getKatalog = (user, id = null) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    let url = `${BASE_API_URL}/katalog`
    if (id) {
        url += `/${id}`
    }
    return axios({
        method: 'GET',
        url: url,
        headers: HEADER,
    })
}

export const updateKatalog = (user, data) => {
    HEADER['X-Api-Key'] = `Bearer ${user.token}`
    HEADER['Content-Type'] = 'multipart/form-data'
    let formData = new FormData()
    if (!data.image) {
        return null;
    }
    formData.append('image', data.image)

    return axios({
        method: 'POST',
        url: `${BASE_API_URL}/katalog/${data.id}`,
        headers: HEADER,
        data: formData
    })
}
