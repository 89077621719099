import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { Box, Flex, Input, InputGroup, InputRightElement, Select, Spacer, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom'
import { AuthContextState } from '../../../../context/AuthContext'
import { useUrlQuery } from '../../../../hooks/useUrlQuery'
import { fotoColumns } from '../../../../lib/const/table-columns'
import { deletedToast, pinContentToast, unauthenticateToast, unpinContentToast } from '../../../../lib/const/toast'
import { capFirstLetter } from '../../../../lib/helper/capitalizeFirstLetter'
import { deletePhoto, getAllPhoto, setPinPhoto } from '../../../../services/user.services'
import PageTitle from '../../_components/PageTitle'
import PinWarning from '../../_components/PinWarning'
import TableData from '../../_components/TableData'
import { sl } from 'date-fns/locale'


function Katalog({ customPage = false }) {

    const [, setTopRightButton] = useOutletContext()
    const param = useParams()
    const [auth, setAuth] = useContext(AuthContextState)
    const [photos, setPhotos] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const [isLoading, setLoading] = useState(true)
    const { addQuery, urlQuery } = useUrlQuery()
    const navigate = useNavigate()
    const toast = useToast()
    const slugPage = 'katalog'
    const pageTitle = customPage ? capFirstLetter(slugPage.split('-').join(' ')) : 'Katalog'

    const pinDisclosure = useDisclosure()
    const pinCancelRef = useRef()

    const deleteHandler = (id) => {
        setLoading(true)
        let payload = { id }

        deletePhoto(auth, payload)
            .then(data => {
                navigate(customPage ? `/halaman/${slugPage}/gallery` : '/beranda/katalog')
                toast(deletedToast)
                console.log(data)
                init()
            })
            .catch(e => {
                setLoading(false)
                const { status, data } = e.response

                if (status === 401) {
                    toast(unauthenticateToast)
                    navigate('/auth/logout')
                }
                if (status === 422) {
                    console.log(data)
                    toast({
                        title: 'Gagal',
                        description: data.message,
                        status: 'error',
                        position: 'top'

                    })
                }
                console.log(e)
            })
    }

    const changePinnedHandler = (id, list) => {
        setLoading(true)
        let payload = {
            id: id,
            pinned: !list.filter(item => item.id === id)[0]['pinned_at']
        }

        if (customPage) {
            payload = { ...payload, slugPage }
        }

        // console.log(payload)

        setPinPhoto(auth, payload, slugPage)
            .then(res => {
                console.log(res)
                if (payload.pinned) {
                    toast(pinContentToast)
                } else {
                    toast(unpinContentToast)
                }
                init()
            })
            .catch(e => {
                console.log(e)
                if (e.response.status === 400) pinDisclosure.onOpen()
                setLoading(false)
            })
    }

    const editHandler = (id) => navigate(customPage ? `/halaman/${slugPage}/gallery/${id}/edit` : `/beranda/katalog/${id}/edit`)

    const responseHandler = (data) => {
        const images = data.data.data.map(item => {
            item.title = {
                title: item.title,
                image: item.file.url
            }

            item.action = {
                delete: () => deleteHandler(item.id),
                edit: () => editHandler(item.id)
            }

            item.pinned_action = {
                onClick: () => changePinnedHandler(item.id, data.data.data),
                pinned: item.pinned_at
            }

            return item
        })

        setPhotos(images)
        setMetaTable({
            current_page: data.data.meta.current_page,
            per_page: data.data.meta.per_page,
            from: data.data.meta.from,
            to: data.data.meta.to,
            total: data.data.meta.total,
            next: data.data.links.next,
            prev: data.data.links.prev,
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)
        const { status, data } = e.response

        if (status === 401) {
            toast(unauthenticateToast)
            navigate('/auth/logout')
        }
        setLoading(false)

    }

    const nextPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page + 1)
    }

    const prevPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page - 1)
    }

    const tableProps = {
        columns: fotoColumns,
        data: photos,
        isLoading,
        metaTable: metaTable,
        prevPageHandler: prevPageHandler,
        nextPageHandler: nextPageHandler
    }

    const init = () => {
        console.log('init')
        let payload = {
            page: urlQuery.get('p') || 1
        }
        payload = { slugPage, ...payload }
        getAllPhoto(auth, payload)
            .then(responseHandler)
            .catch(errorHandler)

    }

    useEffect(() => {
        setLoading(true)
        init()

        setTopRightButton({
            text: 'Tambah Katalog',
            icon: <AddIcon />,
            onClick: () => navigate(customPage ? `/halaman/${slugPage}/gallery/add` : '/beranda/katalog/add')
        })

    }, [urlQuery])

    // searchParam.forEach((item, index) => console.log(index, item))
    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <PinWarning cancelRef={pinCancelRef} {...pinDisclosure} />
            <PageTitle mb={4} title={pageTitle} />
            <Flex>
                <Select placeholder='Tanggal' bg='white' width='20%' mr={2} />
                <Select placeholder='Kategori' bg='white' width='20%' />
                <Spacer />
                <InputGroup width='30%'>
                    <Input placeholder='Ketik untuk mencari' bg='white' />
                    <InputRightElement color='gray.700' >
                        <Search2Icon />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <TableData {...tableProps} />
        </Box>
    )
}

export default Katalog