import { Box, Button, Flex, Grid, GridItem, Image, Input, Select, Spacer, Text, useToast } from '@chakra-ui/react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { createdToast, thumbnailChangedToast, titleSavedToast, unauthenticateToast, updatedToast } from '../../../../lib/const/toast'
import {  addPostContent, addPostThumbnail, addPostTitle, getPost } from '../../../../services/user.services'
import { BASE_API_URL } from '../../../../services/_base.api.url'
import FileUpload from '../../_components/FileUpload'
import TextEditor from '../../_components/TextEditor'
import imgError from '../../../../assets/images/no-image.png'
import { getDateNow } from '../../../../lib/helper/getDateNow'
import { getUserFullName } from '../../../../lib/helper/getUserFullName'
import { capFirstLetter } from '../../../../lib/helper/capitalizeFirstLetter'
import PageTitle from '../../_components/PageTitle'
import { AuthContextState } from '../../../../context/AuthContext'

function ProdukAdd({ onEdit = false, customPage = false }) {
    const navigate = useNavigate()
    const [, setTopRightButton] = useOutletContext()
    const toast = useToast()
    const param = useParams()
    const [auth,] = useContext(AuthContextState)
    const [id, setId] = useState('')
    const [title, setTitle] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [thumbnailUrl, setThumbnailUrl] = useState('')
    const [visibility, setVisibility] = useState('public')
    const [content, setContent] = useState('')
    const [writer, setWriter] = useState('')
    const [datePublish, setDatePublish] = useState(getDateNow())
    // const [datePublishType, setDatePublishType] = useState('text')
    const [titleError, setTitleError] = useState('')
    const [contentError, setContentError] = useState('')
    const [showThumbUploader, setShowThumbUploader] = useState(false)
    const slugPage = customPage ? param.slugPage : ''
    const pageTitle = customPage ? capFirstLetter(slugPage.split('-').join(' ')) : 'Produk'
    const [loading, setLoading] = useState(true)

    const isParamExist = () => {
        return param.slug
    }

    const onPublishHandler = () => {
        setTitleError('')
        setContentError('')

        if (title.trim() === '') {
            setTitleError('Judul harus diisi')

            return
        }

        if (content.trim() === '') {
            setContentError('Konten harus diisi')
            return
        }

        setLoading(true)

        let payload
        if (customPage) {
            payload = { title, content, visibility, published_at: datePublish, author_name: writer || getUserFullName(auth), slug: param.slug, slugPage }
        } else {
            payload = { title, content, visibility, published_at: datePublish, author_name: writer || getUserFullName(auth), slug: param.slug }
        }

        addPostContent(auth, 'produk', payload)
            .then(data => {
                if (onEdit) {
                    toast(updatedToast)
                } else {
                    toast(createdToast)
                }

                navigate(customPage ? `/halaman/${slugPage}/post` : '/beranda/produk')
                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
                const { status, data } = e.response

                if (status === 401) {
                    toast(unauthenticateToast)
                    navigate('/auth/logout')
                }
                if (status === 422) {
                    console.log(data)
                    toast({
                        title: 'Gagal',
                        description: data.message,
                        status: 'error',
                        position: 'top'

                    })
                }
                console.log(e)
            })
    }

    const onTitleHandler = () => {
        setTitleError('')
        if (title.trim() === '') {
            setTitleError('Judul harus diisi')
            return
        }
        setLoading(true)
        let payload
        if (customPage) {
            payload = { title, slugPage }
        } else {
            payload = { title }
        }
        addPostTitle(auth, 'produk', payload)
            .then(data => {
                toast(titleSavedToast)

                navigate(customPage ? `/halaman/${slugPage}/post/${data.data.data.slug}/add` : `/beranda/produk/${data.data.data.slug}/add`)
                setLoading(false)
            })
            .catch(e => {
                const { status, data } = e.response
                // console.log(e.response)
                if (status === 401) {
                    toast(unauthenticateToast)
                    navigate('/auth/logout')
                }
                if (status === 422) {
                    console.log(data)
                    toast({
                        title: 'Gagal',
                        description: data.message,
                        status: 'error',
                        position: 'top'

                    })
                }
                setLoading(false)

            })
    }

    const uploadThumbnailHandler = (file) => {
        if (file) {
            setLoading(true)
            let payload
            if (customPage) {
                payload = { image: file, slug: param.slug, slugPage }
            } else {
                payload = { image: file, slug: param.slug }

            }
            addPostThumbnail(auth, 'produk', payload)
                .then(data => {
                    toast(thumbnailChangedToast)
                    console.log(data.data.data)
                    setShowThumbUploader(false)
                    setLoading(false)
                    init(true)
                })
                .catch(e => {
                    console.log(e)
                    const { status, data } = e.response
                    // console.log(e.response)
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                        // setThumbnailUrl('')
                        setThumbnail('')
                    }
                    setLoading(false)
                })
        }
    }

    const init = (onlyThumbnail = false) => {

        if (!isParamExist()) setLoading(false)

        if (isParamExist()) {
            setLoading(true)
            let payload

            if (customPage) {
                payload = { slug: param.slug, slugPage }
            } else {
                payload = { slug: param.slug }

            }

            getPost(auth, 'produk',payload)
                .then(data => {
                    data = data.data.data
                    console.log(data)

                    setId(data.id)
                    console.log('id', data.id)
                    if (!onlyThumbnail) {
                        setTitle(data.title)
                        setVisibility(data.visibility || 'public')
                        setDatePublish(data.published_at || getDateNow())
                        setWriter(data.author_name || '')
                        setContent(data.content || '')
                    }
                    setThumbnailUrl(data.url_thumbnail || '')

                    if (!data.url_thumbnail) setShowThumbUploader(true)

                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        setTopRightButton(null)

        init()

    }, [])

    return (
        <Grid templateColumns='repeat(12,1fr)' gap={4}>
            <GridItem colSpan={isParamExist() ? 9 : 12} h={20}>
                <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                    <PageTitle title={pageTitle} showIcon={false} mt={0} mb={0} />
                </Box>
                <Box bg='gray.50' color='gray.700' p={4}>
                    <Box>
                        <Text fontWeight={500}>Judul</Text>
                        <Input isInvalid={titleError} disabled={loading} maxLength={500} placeholder='Judul' bg='white' my={4} value={title} onChange={(e) => setTitle(e.target.value)} />
                        {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
                    </Box>
                    {
                        isParamExist() ?
                            <Fragment>
                                <Box>
                                    <Text fontWeight={500} mt={8}>Upload Gambar Sampul</Text>
                                    {
                                        showThumbUploader ?
                                            <Fragment>
                                                <FileUpload accept='image/jpg,image/jpeg,image/png' fileHandler={[thumbnail, setThumbnail]} isLoading={loading} width='100%' callbackAfterChange={uploadThumbnailHandler} />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <Flex justifyContent='center'>
                                                    <Image maxW={200} src={thumbnailUrl ? thumbnailUrl : imgError} />
                                                </Flex>
                                                <Flex justifyContent='center' mt={4}>
                                                    <Button isLoading={loading} mr={4} colorScheme='teal' onClick={() => setShowThumbUploader(true)} >Ubah</Button>
                                                    {/* <Button isLoading={loading} ml={4} colorScheme='red' >Hapus</Button> */}
                                                </Flex>
                                            </Fragment>
                                    }
                                </Box>
                                <Box mt={16} width='99.5%' overflow='auto' >
                                    {
                                        !loading ?
                                            <TextEditor content={content} setContent={setContent} disabled={loading} setParentLoading={setLoading} token={auth.token} uploadImgUrl={`${BASE_API_URL}/pages/${customPage ? slugPage : 'produk'}/posts/${id}/image`} />
                                            : ''
                                    }

                                    {contentError ? <Text fontSize={14} color='red.500'>{contentError}</Text> : ''}
                                </Box>
                            </Fragment> : ''
                    }
                </Box>
                <Flex>
                    <Spacer />
                    <Button isLoading={loading} mt={4} mb={16} colorScheme='teal' bg='teal.500' onClick={isParamExist() ? onPublishHandler : onTitleHandler}>Simpan</Button>
                </Flex>
            </GridItem>
            {
                isParamExist() ?
                    <GridItem colSpan={3} h={20}>
                        <Box color='gray.700' bg='gray.50' rounded={4}>
                            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                                <Text>Status & Visibilitas</Text>
                            </Box>
                            <Box p={4}>
                                <Box pb={4}>
                                    <Text>Visibility</Text>
                                    <Select bg='white' value={visibility} onChange={e => setVisibility(e.target.value)}>
                                        <option value='public' >Publik</option>
                                        <option value='private'>Private</option>
                                    </Select>
                                </Box>
                                <Box pb={4}>
                                    <Text>Publish</Text>
                                    {/* <Input type={datePublishType} value={datePublish} onBlur={() => setDatePublishType('text')} onFocus={() => setDatePublishType('date')} onChange={(e) => { setDatePublish(`${format(new Date(e.target.value), 'dd/MM/yyyy')}`); e.target.blur() }} bg='white' placeholder='Tanggal Publish' /> */}
                                    <Input type='date' value={datePublish} onChange={(e) => { setDatePublish(e.target.value); console.log(e.target.value) }} bg='white' min={new Date().toISOString().split("T")[0]} placeholder='Tanggal Publish' />
                                </Box>
                                <Box pb={4}>
                                    <Text>Penulis</Text>
                                    <Input disabled={loading} placeholder='Penulis' bg='white' my={4} value={writer} onChange={(e) => setWriter(e.target.value)} />
                                    <Text fontSize={12} color='gray.400' >*Jika kosong, maka nama pengguna yang sekarang akan digunakan</Text>
                                </Box>
                            </Box>
                        </Box>
                    </GridItem> : ''
            }
        </Grid>
    )
}


export default ProdukAdd