export const LIST_SIDEBAR = [
    {
        name: 'Dashboard',
        link: '/dashboard',
        child: [],
    },
    {
        name: 'Beranda',
        link: '/beranda',
        child: [
            {
                name: 'Banner',
                link: '/beranda/banner',
                child: [],
            },
            {
                name: 'Tentang',
                link: '/beranda/tentang-kami',
                child: [],
            },
            {
                name: 'Opening',
                link: '/beranda/opening',
                child: [],
            },
            {
                name: 'Keunggulan',
                link: '/beranda/keunggulan',
                child: [],
            },
            {
                name: 'Katalog',
                link: '/beranda/katalog',
                child: [],
            },
            {
                name: 'Produk',
                link: '/beranda/produk',
                child: [],
            },
            {
                name: 'Distributor',
                link: '/beranda/distributor',
                child: [],
            },

            {
                name: 'Galeri Foto',
                link: '/beranda/foto',
                child: [],
            },
        ],
    },
    {
        name: 'Halaman',
        link: '/halaman',
        child: [
            {
                name: 'Semua Halaman',
                link: '/halaman/',
                child: [],
            },
            {
                name: 'Tambah Halaman',
                link: '/halaman/add',
                child: [],
            }
        ],
    },
    {
        name: 'Komentar',
        link: '/komentar',
        child: [],
    },
    // {
    //     name: 'Download',
    //     link: '/download',
    //     child: [],
    // },


]