import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Icon, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { useRef } from 'react'
import { HiDotsVertical } from 'react-icons/hi'
import { BsPersonCheck } from 'react-icons/bs'

function ActionListTableItem({ id, onEdit = null, onDelete = null, approveFunction = null, disabled = false, onShow = null }) {
    const deleteItem = useDisclosure()
    const cancelRef = useRef()

    return (
        <Fragment>

            <Menu>
                <MenuButton isDisabled={disabled}>
                    <Icon as={HiDotsVertical} />
                </MenuButton>
                <MenuList>
                    {
                        approveFunction ?

                            <MenuItem isDisabled={disabled} icon={<BsPersonCheck />} onClick={approveFunction.approve} >{approveFunction.value ? 'Unapproved' : 'Approve'}</MenuItem>
                            :
                            ''
                    }
                    {
                        onShow !== null ?
                            <MenuItem isDisabled={disabled} icon={<EditIcon />} onClick={onShow} >Tampilkan Item</MenuItem>
                            : ''
                    }
                    {
                        onEdit !== null ?
                            <MenuItem isDisabled={disabled} icon={<EditIcon />} onClick={onEdit} >Edit Item</MenuItem>
                            : ''
                    }
                    {
                        onDelete !== null ?
                            <MenuItem isDisabled={disabled} color='red' icon={<DeleteIcon />} onClick={deleteItem.onOpen} >Hapus</MenuItem> : ''}
                </MenuList>
            </Menu>
            <DeleteConfirmation {...deleteItem} onDelete={onDelete} cancelRefe={cancelRef} />
        </Fragment>
    )
}

function DeleteConfirmation({ isOpen, onOpen, onClose, cancelRef, onDelete }) {

    return (
        <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader>
                    Konfirmasi
                </AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    Item ini akan dihapus.
                    Lanjutkan?
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose} mr={4}>No</Button>
                    <Button onClick={onDelete} colorScheme='red'>Yes</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default ActionListTableItem
