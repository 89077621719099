import { Box, Button, Center, Flex, Image, Input, Select, Spacer, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { AuthContextState } from '../../../../context/AuthContext'
import { createdToast, imageEmptyToast, imageReadyToast, unauthenticateToast, updatedToast } from '../../../../lib/const/toast'
import { capFirstLetter } from '../../../../lib/helper/capitalizeFirstLetter'
import { getDateNow } from '../../../../lib/helper/getDateNow'
import { addPhoto, addPhotoTemp, getPhoto, updateFilePhoto, updatePhoto } from '../../../../services/user.services'
import FileUpload from '../../_components/FileUpload'
import PageTitle from '../../_components/PageTitle'

function KatalogAdd({ onEdit = false, customPage = false }) {
    const [auth,] = useContext(AuthContextState)
    const [, setTopRightButton] = useOutletContext()
    const [title, setTitle] = useState('')
    const param = useParams()
    const [titleError, setTitleError] = useState('')
    const [desc, setDesc] = useState('')
    const [descError, setDescError] = useState('')
    const [lang,] = useState('id')
    const [fileTempId, setFileTempId] = useState('')
    const [visibility, setVisibility] = useState('public')
    const [date, setDate] = useState(getDateNow())
    const [imageUpload, setImageUpload] = useState(null)
    const [loading, setLoading] = useState(true)
    const [imgUrl, setImgUrl] = useState('')
    const navigate = useNavigate()
    const [showPreview, setShowPreview] = useState(onEdit)
    const slugPage = 'katalog'
    const pageTitle = customPage ? capFirstLetter(slugPage.split('-').join(' ')) : 'Katalog'
    const toast = useToast()

    const onPublishHandler = () => {
        setTitleError('')
        setDescError('')

        if (title.trim() === '') {
            setTitleError('Judul harus diisi')
            return
        }

        if (desc.trim() === '') {
            setDescError('Deskripsi harus diisi')
            return
        }

        if (!onEdit) {
            if (fileTempId === ' ' || fileTempId === '') {
                toast(imageEmptyToast)
                return
            }
        }

        setLoading(true)

        let payload = {
            title: title,
            description: desc,
            lang: lang,
            file_temp_id: fileTempId,
            visibility: visibility,
            published_at: date
        }
        if (customPage) {
            payload = { ...payload, slugPage }
        }

        if (!onEdit) {
            addPhoto(auth, payload, slugPage)
                .then(data => {
                    toast(createdToast)

                    navigate(customPage ? `/halaman/${slugPage}/gallery` : '/beranda/katalog')
                    setLoading(false)
                })
                .catch(e => {
                    setLoading(false)
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                    console.log(e)
                })
        } else {
            updatePhoto(auth, { id: param.id, ...payload }, slugPage)
                .then(data => {
                    toast(updatedToast)

                    navigate(customPage ? `/halaman/${slugPage}/gallery` : '/beranda/katalog')
                    setLoading(false)
                })
                .catch(e => {
                    setLoading(false)
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                    console.log(e)
                })
        }
    }

    const photoTempHandler = (file) => {
        if (file) {
            setLoading(true)
            let payload = { file }

            if (customPage) {
                payload = { ...payload, slugPage }
            }

            addPhotoTemp(auth, payload, slugPage)
                .then(data => {
                    setLoading(false)
                    setFileTempId(data.data.data.key_temp)
                    toast(imageReadyToast)
                    console.log(data)
                })
                .catch(e => {
                    console.log(e)
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                    setLoading(false)
                })
        }
    }


    const updateFilePhotoHandler = (file) => {
        if (file) {
            setLoading(true)
            let payload = { file, id: param.id }

            if (customPage) {
                payload = { ...payload, slugPage }
            }

            updateFilePhoto(auth, payload, slugPage)
                .then(data => {
                    setLoading(false)
                    setShowPreview(false)
                    toast(imageReadyToast)
                    console.log(data)
                })
                .catch(e => {
                    console.log(e)
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                    setLoading(false)
                })
        }
    }



    const init = () => {
        if (!onEdit) {
            setLoading(false)
        } else {
            let payload = { id: param.id }
            if (customPage) {
                payload = { ...payload, slugPage }
            }
            getPhoto(auth, payload, slugPage)
                .then(data => {
                    const photo = data.data.data
                    console.log(photo)
                    setTitle(photo.title)
                    setDesc(photo.description)
                    setVisibility(photo.visibility)
                    setDate(photo.published_at || getDateNow())
                    setImgUrl(photo.file.url)
                    setLoading(false)
                })
                .catch(e => {
                    console.log(e)
                    const { status, data } = e.response
                    if (status === 401) {
                        toast(unauthenticateToast)
                        navigate('/auth/logout')
                    }
                    if (status === 422) {
                        console.log(data)
                        toast({
                            title: 'Gagal',
                            description: data.message,
                            status: 'error',
                            position: 'top'

                        })
                    }
                })
        }

    }

    useEffect(() => {
        init()
        setTopRightButton(null)
    }, [])
    return (
        <Fragment>
            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                <PageTitle title={pageTitle} showIcon={false} mt={0} mb={0} />
            </Box>
            <Box bg='gray.50' p={4} color='gray.700'>
                <Box>
                    <Text fontWeight={500}>Judul Gambar</Text>
                    <Input isInvalid={titleError} disabled={loading} maxLength={500} placeholder='Judul Gambar' bg='white' my={4} value={title} onChange={(e) => setTitle(e.target.value)} />
                    {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
                </Box>
                <Box mt={2}>
                    <Text fontWeight={500}>Upload Gambar</Text>
                    {
                        showPreview ?
                            <Center>
                                <Image maxWidth={200} src={imgUrl} alt='Preview' />
                            </Center>
                            : ''
                    }
                    <FileUpload accept='image/jpeg,image/png,image/gif,image/svg+xml' isLoading={loading} fileHandler={[imageUpload, setImageUpload]} callbackAfterChange={onEdit ? updateFilePhotoHandler : photoTempHandler} width='100%' />
                </Box>
                <Box mt={6}>
                    <Text fontWeight={500}>Deskripsi Gambar</Text>
                    <Textarea isInvalid={descError} disabled={loading} maxLength={3000} placeholder='Deskripsi Gambar' bg='white' my={4} value={desc} onChange={(e) => setDesc(e.target.value)} />
                    {descError ? <Text fontSize={14} color='red.500'>{descError}</Text> : ''}
                </Box>

                <Box mt={8} color='gray.700' bg='gray.50' rounded={4}>
                    <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                        <Text>Publish</Text>
                    </Box>
                    <Box p={4} w='50%'>
                        <Box pb={4}>
                            <Text>Visibility</Text>
                            <Select disabled={loading} bg='white' value={visibility} onChange={e => { setVisibility(e.target.value); console.log(e.target.value); }}>
                                <option value='private' >Private</option>
                                <option value='public' >Publik</option>
                            </Select>
                        </Box>
                        <Box pb={4}>
                            <Text>Publish</Text>
                            <Input disabled={loading} type='date' value={date} onChange={(e) => { setDate(e.target.value); console.log(e.target.value) }} bg='white' min={new Date().toISOString().split("T")[0]} placeholder='Tanggal Publish' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Flex my={4}>
                <Spacer />
                <Button disabled={loading} colorScheme='teal' onClick={onPublishHandler}>Simpan</Button>
            </Flex>
        </Fragment>
    )
}

export default KatalogAdd