import { Search2Icon } from '@chakra-ui/icons'
import { Box, Flex, Input, InputGroup, InputRightElement, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate} from 'react-router-dom'
import { AuthContextState } from '../../../../context/AuthContext'
import { useUrlQuery } from '../../../../hooks/useUrlQuery'
import { keunggulanColumns } from '../../../../lib/const/table-columns'
import { getKeunggulan } from '../../../../services/user.services'
import PageTitle from '../../_components/PageTitle'
import PinWarning from '../../_components/PinWarning'
import TableData from '../../_components/TableData'

function Keunggulan() {
    const [auth] = useContext(AuthContextState)
    const [keunggulan, setKeunggulan] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const [isLoading, setLoading] = useState(true)
    const { urlQuery, addQuery } = useUrlQuery()
    const navigate = useNavigate()

    const pinDisclosure = useDisclosure()
    const pinCancelRef = useRef()

    const editHandler = (slug) => navigate(`/beranda/keunggulan/${slug}`)

    const responseHandler = (data) => {
        console.log(data)
        const result = data.data.data.child.map(item => {
            item.action = {
                // delete: () => deleteHandler(item.slug),
                edit: () => editHandler(item.id)
            }
            // item.pinned_action = {
            //     onClick: () => changePinnedHandler(item.id, item.slug, data.data.data),
            //     pinned: item.pinned_at
            // }

            item.author_name = item.author_name || 'Admin'
            return item
        })
        setKeunggulan(result)
        setMetaTable({
            current_page: 1,
            per_page: 8,
            from: 1,
            to: result.length,
            total: result.length,
            next: '',
            prev: '',
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)
        setLoading(false)
    }

    const nextPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page + 1)
    }

    const prevPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page - 1)
    }

    const init = () => {
        getKeunggulan(auth)
            .then(responseHandler)
            .catch(errorHandler)
    }

    useEffect(() => {
        init()
        // setTopRightButton({
        //     text: 'Tambah Publikasi',
        //     icon: <AddIcon />,
        //     onClick: () => navigate(customPage ? `/halaman/${slugPage}/publication/add` : '/beranda/publikasi/add')
        // })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlQuery])

    const tableProps = {
        columns: keunggulanColumns,
        data: keunggulan,
        isLoading,
        metaTable,
        prevPageHandler,
        nextPageHandler
    }


    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <PinWarning cancelRef={pinCancelRef} {...pinDisclosure} />
            <PageTitle mb={4} title="Keunggulan" />
            <Flex>
                <InputGroup width='30%'>
                    <Input placeholder='Ketik untuk mencari' bg='white' />
                    <InputRightElement color='gray.700' >
                        <Search2Icon />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <TableData {...tableProps} />
        </Box>
    )
}

export default Keunggulan